// Quasar Sass (& SCSS) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.
$body-font-size   : 12px
$primary   : #090705
$secondary : #f58635
$accent    : #9C27B0
$dark      : #1D1D1D
$positive  : #f58635
$negative  : #C10015
$info      : #31CCEC
$warning   : #F2C037

$editor-border-radius : 4px
$button-border-radius : 2px
$generic-border-radius : 4

$img-loading-font-size : 30px